import React, { useEffect, useRef, useMemo, useState } from "react";
import GeoJSON from "ol/format/GeoJSON.js";
import Map from "ol/Map.js";
import View from "ol/View.js";
import { Fill, Style } from "ol/style.js";
import { Vector as VectorSource } from "ol/source.js";
//import Stamen from 'ol/source/Stamen.js';
import OSM from 'ol/source/OSM';
import { Vector as VectorLayer } from "ol/layer.js";
import { MultiPolygon } from "ol/geom";
import TileLayer from "ol/layer/Tile.js";
import TileWMS from "ol/source/TileWMS.js";
import { fromLonLat } from "ol/proj.js";
import { getVectorContext } from "ol/render.js";
import "ol/ol.css";
import MapContext from "./MapContext";

export const OlMap = (props) => {
  const mapRef = useRef();
  const [map, setMap] = useState(null);

  const background = useMemo(
    () =>
      new TileLayer({
        className: "stamen",
        /*source: new Stamen({
          layer: "toner",
        }),*/
        source: new OSM()
      }),
    []
  );

  useEffect(() => {
    const base = new TileLayer({
      source: new TileWMS({
        url: "https://services.terrascope.be/wms/v2?",
        params: {
          LAYERS: "WORLDCOVER_2021_MAP",
          TILED: "true",
          VERSION: "1.1.1",
        },
        format: "image/png",
        transparent: true,
        transition: 0,
      }),
    });

    const clipLayer = new VectorLayer({
      style: null,
      source: new VectorSource({
        url: "/api/climate/campBoundary",
        format: new GeoJSON(),
      }),
    });

    //Giving the clipped layer an extent is necessary to avoid rendering when the feature is outside the viewport
    clipLayer.getSource().on("addfeature", function () {
      base.setExtent(clipLayer.getSource().getExtent());
    });
    const style = new Style({
      fill: new Fill({
        color: "black",
      }),
    });

    let camp = [props.camp];
    let boundary = new TileLayer({
      source: new TileWMS({
        url: "http://188.40.139.182:8080/geoserver/zmb_camps/wms",
        params: {
          LAYERS: "zmb_camps:zmb_camps",
          TILED: "true",
          VERSION: "1.1.1",
          CQL_FILTER: "Camp='" + props.camp + "'",
        },
        format: "image/png",
        transparent: true,
        transition: 0,
      }),
    });
    let extent = 0;
    let mapObject = new Map({
      layers: [background, base, clipLayer, boundary],
      target: "map-container",
      view: new View({
        center: fromLonLat([35.178326, -6.776012]),
        zoom: 6,
      }),
    });
    base.on("postrender", function (e) {
      let polygons = [];
      clipLayer.getSource().forEachFeature(function (feature) {
        let name = feature.get("Camp");
        if (camp.indexOf(name) > -1) {
          let geometry = feature.getGeometry();
          let type = geometry.getType();
          if (type === "Polygon") {
            polygons.push(geometry);
          } else if (type === "MultiPolygon") {
            Array.prototype.push.apply(polygons, geometry.getPolygons());
          }
          extent = feature.getGeometry().getExtent();
          mapObject.getView().fit(extent);
        }
      });
      let multiPolygon = new MultiPolygon([]);
      polygons.forEach(function (polygon) {
        multiPolygon.appendPolygon(polygon);
      });
      e.context.globalCompositeOperation = "destination-in";
      let vectorContext = getVectorContext(e);
      vectorContext.setStyle(style);
      vectorContext.drawGeometry(multiPolygon);
      e.context.globalCompositeOperation = "source-over";
    });

    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, [background, props.camp]);

  return (
    <MapContext.Provider value={{ map }}>
      <div
        style={{ height: "80vh", width: "100%" }}
        ref={mapRef}
        className="map-container"
        id="map-container"
      ></div>
    </MapContext.Provider>
  );
};
