import { combineReducers } from "redux";
import { changeProvinceNameReducer } from "./reducers/changeProvinceNameReducer";
import { changeDistrictNameReducer } from "./reducers/changeDistrictNameReducer";
import { changeCampNameReducer } from "./reducers/changeCampNameReducer";
import { changeBlockNameReducer } from "./reducers/changeBlockNameReducer";

const rootReducer = combineReducers({
  provinceName: changeProvinceNameReducer,
  districtName: changeDistrictNameReducer,
  blockName: changeBlockNameReducer,
  campName: changeCampNameReducer,
});

export default rootReducer;
