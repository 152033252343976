import React, { useState, useEffect, Fragment } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { Auth } from "aws-amplify";
import FileDownloadIcon from '@mui/icons-material/FileDownload';



export const SimpleDialog = (props) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState([]);
  const [provinces, setProvinces] = useState([]);


  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    props.sendCheckList(checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = () => {
    setOpen(false);
    props.handleDownload(checked)
  };

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      fetch("/api/climate/getProvinceNames", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setProvinces(data);
        });
    }
    genToken()
  }, [])

  return (
    <Fragment>
      <Button onClick={handleClickOpen} variant="contained" endIcon={<FileDownloadIcon />} style={{ backgroundColor: "#0039E6" }}>
        Download
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Province</DialogTitle>
        <DialogContent>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {provinces.map((value) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem
                  key={value}
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleDownload}>Download</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}