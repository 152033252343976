import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Label, Spinner } from "reactstrap";
import { ColumnChart } from "./ColumnChart";
import { TimeSeries } from "./TimeSeries";
import { Auth } from "aws-amplify";
import { LandCover } from "./LandCover";
import { OlMap } from "../DataLibrary/OlMap";
import Select from "react-select";
import { Pie } from "./Pie";
import { changeProvinceName } from "../../redux/action";
import { changeDistrictName } from "../../redux/action";
import { changeBlockName } from "../../redux/action";
import { changeCampName } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Papa from 'papaparse';
import { SimpleDialog } from "./SimpleDialog";
import { AiOutlineDownload } from "react-icons/ai";




export const DataLibrary = () => {
  const [provinces, setProvinces] = useState([]);
  const provinceName = useSelector((state) => state.provinceName);
  const [districts, setDistricts] = useState([]);
  const districtName = useSelector((state) => state.districtName);
  const [blocks, setBlocks] = useState([]);
  const blockName = useSelector((state) => state.blockName);
  const [camps, setCamps] = useState([]);
  const campName = useSelector((state) => state.campName);
  const [yearlyRainfall, setYearlyRainfall] = useState([]);
  const [tamsatyearlyRainfall, setTamsatYearlyRainfall] = useState([]);
  const [tamsatmonthlyRainfall, setTamsatMonthlyRainfall] = useState([]);
  const [tamsatdailyRainfall, setTamsatDailyRainfall] = useState([]);
  const [monthlyRainfall, setMonthlyRainfall] = useState([]);
  const [landCover, setLandCover] = useState([]);
  const [ndvi, setNdvi] = useState([]);
  let [loading, setLoading] = useState(true);
  const [ndviBulkLoading, setNdviBulkLoading] = useState(false);
  const [chirpsMonthlyBulkLoading, setChirpsMonthlyBulkLoading] = useState(false);
  const [chirpsYearlyBulkLoading, setChirpsYearlyBulkLoading] = useState(false);
  const [tamsatDailyBulkLoading, setTamsatDailyBulkLoading] = useState(false);
  const [tamsatMonthlyBulkLoading, setTamsatMonthlyBulkLoading] = useState(false);
  const [tamsatYearlyBulkLoading, setTamsatYearlyBulkLoading] = useState(false);
  const [landCoverBulkLoading, setLandcoverBulkLoading] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const dispatch = useDispatch();

  const handleCheckListLandCover = (e) => {
    setCheckedList(e)
  }

  const handleCheckListNDVI = (e) => {
    setCheckedList(e)
  }

  const handleCheckListChirpsMonthly = (e) => {
    setCheckedList(e)
  }

  const handleCheckListChirpsYearly = (e) => {
    setCheckedList(e)
  }

  const handleCheckListTamsatDaily = (e) => {
    setCheckedList(e)
  }

  const handleCheckListTamsatMonthly = (e) => {
    setCheckedList(e)
  }

  const handleCheckListTamsatYearly = (e) => {
    setCheckedList(e)
  }


  function isNDVINullMoreThan25Percent(arrayOfObjects) {
    if (!Array.isArray(arrayOfObjects)) {
      throw new Error('Input should be an array');
    }
    const totalObjects = arrayOfObjects.length;
    if (totalObjects === 0) {
      return false; // Return false if the array is empty
    }
    let nullCount = 0;
    for (const obj of arrayOfObjects) {
      if (obj.ndvi === null) {
        nullCount++;
      }
    }
    let currentyear = new Date().getFullYear();
    let totalData = 12 * 3 * (currentyear - 2003)
    console.log(nullCount, totalObjects / 4, totalObjects, totalData * 0.75)

    if (nullCount > totalObjects / 4 || totalObjects < totalData * 0.75)
      return true
    else
      return false
  }


  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      fetch("/api/climate/getProvinceNames", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },

      })
        .then((response) => response.json())
        .then((data) => {
          setProvinces(data);
        });
      if (provinceName !== null) {
        fetch("/api/climate/getDistrictNames?province_name=" + provinceName, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setDistricts(data);
          });
        if (districtName !== null) {
          fetch(
            "/api/climate/getBlockNames?province_name=" +
            provinceName +
            "&district_name=" +
            districtName,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              setBlocks(data);
            });

          if (blockName !== null) {
            fetch(
              "/api/climate/getCampNames?province_name=" +
              provinceName +
              "&district_name=" +
              districtName + "&block_name=" +
              blockName,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwtToken,
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                setCamps(data);
              });
          }
        }
      }
      if (provinceName !== null && districtName !== null && blockName !== null && campName !== null) {
        setLoading(false);
        fetch(
          "/api/climate/landCoverPercent?province_name=" +
          provinceName +
          "&district_name=" +
          districtName +
          "&block_name=" +
          blockName +
          "&camp_name=" +
          campName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setLandCover(data);
          });

        fetch(
          "/api/climate/historicalNDVI?province_name=" +
          provinceName +
          "&district_name=" +
          districtName +
          "&block_name=" +
          blockName +
          "&camp_name=" +
          campName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then(async (response) => response.json())
          .then((data) => {
            setNdvi(data);
          });

        fetch(
          "/api/climate/chirpsYearlyRainfall?province_name=" +
          provinceName +
          "&district_name=" +
          districtName +
          "&block_name=" +
          blockName +
          "&camp_name=" +
          campName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setYearlyRainfall(data);
          });

        fetch(
          "/api/climate/chirpsMonthlyRainfall?province_name=" +
          provinceName +
          "&district_name=" +
          districtName +
          "&block_name=" +
          blockName +
          "&camp_name=" +
          campName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setMonthlyRainfall(data);
          });

        fetch(
          "/api/climate/tamsatYearlyRainfall?province_name=" +
          provinceName +
          "&district_name=" +
          districtName +
          "&block_name=" +
          blockName +
          "&camp_name=" +
          campName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setTamsatYearlyRainfall(data);
          });


        fetch(
          "/api/climate/tamsatMonthlyRainfall?province_name=" +
          provinceName +
          "&district_name=" +
          districtName +
          "&block_name=" +
          blockName +
          "&camp_name=" +
          campName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setTamsatMonthlyRainfall(data);
          });
        setLoading(true);


        fetch(
          "/api/climate/tamsatDailyRainfall?province_name=" +
          provinceName +
          "&district_name=" +
          districtName +
          "&block_name=" +
          blockName +
          "&camp_name=" +
          campName,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setTamsatDailyRainfall(data);
          });
        setLoading(true);
      }
    }
    genToken();
  }, [provinceName, districtName, blockName, campName]);

  let provinceObject = [];
  let districtObject = [];
  let blockObject = [];
  let campObject = [];

  for (let j in provinces) {
    let items = {};
    items["label"] = provinces[j];
    items["value"] = provinces[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    provinceObject.push(json_obj);
  }
  for (let j in districts) {
    let items = {};
    items["label"] = districts[j];
    items["value"] = districts[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    districtObject.push(json_obj);
  }
  for (let j in blocks) {
    let items = {};
    items["label"] = blocks[j];
    items["value"] = blocks[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    blockObject.push(json_obj);
  }
  for (let j in camps) {
    let items = {};
    items["label"] = camps[j];
    items["value"] = camps[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    campObject.push(json_obj);
  }
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
  };

  const ndviWardDownload = () =>{
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        `/api/climate/wardNDVIDownload?province_name=${provinceName}&district_name=${districtName}&block_name=${blockName}&camp_name=${campName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          }
        }
      )
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get('Content-Disposition');
          if (!contentDisposition || !contentDisposition.includes('attachment')) {
            throw new Error('Invalid or missing Content-Disposition header');
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = `${provinceName}_${districtName}_${blockName}_${campName}.csv`;

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: 'text/csv' });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(a.href);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error);
        });
    }
    genToken();
  }

  const chirpsMonthlyWardDownload = () =>{
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        `/api/climate/wardChirpsMonthlyRainfallDownload?province_name=${provinceName}&district_name=${districtName}&block_name=${blockName}&camp_name=${campName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          }
        }
      )
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get('Content-Disposition');
          if (!contentDisposition || !contentDisposition.includes('attachment')) {
            throw new Error('Invalid or missing Content-Disposition header');
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = `${provinceName}_${districtName}_${blockName}_${campName}.csv`;

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: 'text/csv' });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(a.href);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error);
        });
    }
    genToken();
  }

  const tamsatMonthlyWardDownload = () =>{
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        `/api/climate/wardTamsatMonthlyRainfallDownload?province_name=${provinceName}&district_name=${districtName}&block_name=${blockName}&camp_name=${campName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          }
        }
      )
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get('Content-Disposition');
          if (!contentDisposition || !contentDisposition.includes('attachment')) {
            throw new Error('Invalid or missing Content-Disposition header');
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = `${provinceName}_${districtName}_${blockName}_${campName}.csv`;

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: 'text/csv' });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(a.href);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error);
        });
    }
    genToken();
  }

  const tamsatDailyWardDownload = () =>{
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        `/api/climate/wardTamsatDailyRainfallDownload?province_name=${provinceName}&district_name=${districtName}&block_name=${blockName}&camp_name=${campName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          }
        }
      )
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get('Content-Disposition');
          if (!contentDisposition || !contentDisposition.includes('attachment')) {
            throw new Error('Invalid or missing Content-Disposition header');
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = `${provinceName}_${districtName}_${blockName}_${campName}.csv`;

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: 'text/csv' });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(a.href);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error);
        });
    }
    genToken();
  }

  const chirpsYearlyWardDownload = () => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        `/api/climate/wardChirpsYearlyRainfallDownload?province_name=${provinceName}&district_name=${districtName}&block_name=${blockName}&camp_name=${campName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          }
        }
      )
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get('Content-Disposition');
          if (!contentDisposition || !contentDisposition.includes('attachment')) {
            throw new Error('Invalid or missing Content-Disposition header');
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = `${provinceName}_${districtName}_${blockName}_${campName}.csv`;

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: 'text/csv' });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(a.href);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error);
        });
    }
    genToken();
  }

  const tamsatYearlyWardDownload = () => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        `/api/climate/wardTamsatYearlyRainfallDownload?province_name=${provinceName}&district_name=${districtName}&block_name=${blockName}&camp_name=${campName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          }
        }
      )
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get('Content-Disposition');
          if (!contentDisposition || !contentDisposition.includes('attachment')) {
            throw new Error('Invalid or missing Content-Disposition header');
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = `${provinceName}_${districtName}_${blockName}_${campName}.csv`;

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: 'text/csv' });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(a.href);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error);
        });
    }
    genToken();
  }

  

  const handleBulkLandcoverDownload = (checkList) => {
    setLandcoverBulkLoading(true)
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/climate/getAllCampInfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
          body: JSON.stringify({
            'province_array': checkList
          })
        }
      )
        .then(async (response) => await response.json())
        .then((data) => {
          const csv = Papa.unparse(data);
         
          const blob = new Blob([csv], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download =  'Landcover.csv';
          a.click();
          window.URL.revokeObjectURL(url);
          setLandcoverBulkLoading(false)
        })
        .catch((error) => {
          // Handle the error
          console.error("Error:", error);
          setLandcoverBulkLoading(false)
        });
    }
    genToken();
  };

  const handleBulkNDVIDownload = (checkList) => {
    setNdviBulkLoading(true);
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/climate/bulkNDVIDownload",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
          body: JSON.stringify({
            'province_array': checkList
          })
        }
      )
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get('Content-Disposition');
          if (!contentDisposition || !contentDisposition.includes('attachment')) {
            throw new Error('Invalid or missing Content-Disposition header');
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = 'NDVI (modified modis + eviirs).zip';

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: 'application/zip' });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(a.href);

          // Set loading state if needed
          setNdviBulkLoading(false);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error);
          setNdviBulkLoading(false); // Ensure loading state is updated on error
        });
    }
    genToken();
  };

  const handleBulkMonthlyChirpsDownload = (checkedList) => {
    setChirpsMonthlyBulkLoading(true)
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/climate/bulkChirpsMonthlyRainfallDownload",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
          body: JSON.stringify({
            'province_array': checkedList
          })
        }
      )
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get('Content-Disposition');
          if (!contentDisposition || !contentDisposition.includes('attachment')) {
            throw new Error('Invalid or missing Content-Disposition header');
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = 'Chirps Monthly Rainfall.zip';

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: 'application/zip' });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(a.href);

          // Set loading state if needed
          setChirpsMonthlyBulkLoading(false);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error);
          setChirpsMonthlyBulkLoading(false); // Ensure loading state is updated on error
        });
    }
    genToken();
  };

  const handleBulkYearlyChirpsDownload = (checkedList) => {
    setChirpsYearlyBulkLoading(true)
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/climate/bulkChirpsYearlyRainfallDownload",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
          body: JSON.stringify({
            'province_array': checkedList
          })
        }
      )
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Check if the response contains the 'Content-Disposition' header
        const contentDisposition = response.headers.get('Content-Disposition');
        if (!contentDisposition || !contentDisposition.includes('attachment')) {
          throw new Error('Invalid or missing Content-Disposition header');
        }

        // Get the filename from the 'Content-Disposition' header
        const filename = 'Chirps Yearly Rainfall.zip';

        // Assume that 'data' is a zip file binary data
        return response.blob().then((data) => ({ data, filename }));
      })
      .then(({ data, filename }) => {
        // Create a link element and trigger the download
        const blob = new Blob([data], { type: 'application/zip' });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();

        // Revoke the object URL to free up resources
        window.URL.revokeObjectURL(a.href);

        // Set loading state if needed
        setChirpsYearlyBulkLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
        setChirpsYearlyBulkLoading(false); // Ensure loading state is updated on error
      });
    }
    genToken();
  };


  const handleBulkYearlyTamsatDownload = (checkedList) => {
    setTamsatYearlyBulkLoading(true)
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/climate/bulkTamsatYearlyRainfallDownload",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
          body: JSON.stringify({
            'province_array': checkedList
          })
        }
      )
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Check if the response contains the 'Content-Disposition' header
        const contentDisposition = response.headers.get('Content-Disposition');
        if (!contentDisposition || !contentDisposition.includes('attachment')) {
          throw new Error('Invalid or missing Content-Disposition header');
        }

        // Get the filename from the 'Content-Disposition' header
        const filename = 'Tamsat Yearly Rainfall.zip';

        // Assume that 'data' is a zip file binary data
        return response.blob().then((data) => ({ data, filename }));
      })
      .then(({ data, filename }) => {
        // Create a link element and trigger the download
        const blob = new Blob([data], { type: 'application/zip' });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();

        // Revoke the object URL to free up resources
        window.URL.revokeObjectURL(a.href);

        // Set loading state if needed
        setTamsatYearlyBulkLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
        setTamsatYearlyBulkLoading(false); // Ensure loading state is updated on error
      });
    }
    genToken();
  };


  const handleBulkMonthlyTamsatDownload = (checkedList) => {
    setTamsatMonthlyBulkLoading(true);
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/climate/bulkTamsatMonthlyRainfallDownload",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
          body: JSON.stringify({
            'province_array': checkedList
          })
        }
      )
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Check if the response contains the 'Content-Disposition' header
        const contentDisposition = response.headers.get('Content-Disposition');
        if (!contentDisposition || !contentDisposition.includes('attachment')) {
          throw new Error('Invalid or missing Content-Disposition header');
        }

        // Get the filename from the 'Content-Disposition' header
        const filename = 'Tamsat Monthly Rainfall.zip';

        // Assume that 'data' is a zip file binary data
        return response.blob().then((data) => ({ data, filename }));
      })
      .then(({ data, filename }) => {
        // Create a link element and trigger the download
        const blob = new Blob([data], { type: 'application/zip' });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();

        // Revoke the object URL to free up resources
        window.URL.revokeObjectURL(a.href);

        // Set loading state if needed
        setTamsatMonthlyBulkLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
        setTamsatMonthlyBulkLoading(false); // Ensure loading state is updated on error
      });
    }
    genToken();
  };

  const handleBulkDailyTamsatDownload = (checkedList) => {
    setTamsatDailyBulkLoading(true)
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      await fetch(
        "/api/climate/bulkTamsatDailyRainfallDownload",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
          body: JSON.stringify({
            'province_array': checkedList
          })

        }
      )
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Check if the response contains the 'Content-Disposition' header
        const contentDisposition = response.headers.get('Content-Disposition');
        if (!contentDisposition || !contentDisposition.includes('attachment')) {
          throw new Error('Invalid or missing Content-Disposition header');
        }

        // Get the filename from the 'Content-Disposition' header
        const filename = 'Tamsat Daily Rainfall.zip';

        // Assume that 'data' is a zip file binary data
        return response.blob().then((data) => ({ data, filename }));
      })
      .then(({ data, filename }) => {
        // Create a link element and trigger the download
        const blob = new Blob([data], { type: 'application/zip' });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();

        // Revoke the object URL to free up resources
        window.URL.revokeObjectURL(a.href);

        // Set loading state if needed
        setTamsatDailyBulkLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
        setTamsatDailyBulkLoading(false); // Ensure loading state is updated on error
      });
    }
    genToken();
  };

  return (
    <>
      <div
        className="row justify-content-center"
        style={{
          backgroundColor: "#EFEFEF",
          minHeight: "calc(100vh - 80px)",
        }}
      >
        <div className="col-md-6">
          <div className="row m-1">
            <div className="col-md-12">
              <Card
                className="m-1"
                body
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#f5f5f5",
                  height: "85vh",
                }}
              >
                <OlMap camp={campName} province={provinceName} block={blockName} district={districtName} />
                <div className="row justify-content-center mt-1">
                  <div className="col-md-12">
                    <div className="row justify-content-center">
                      <div className="col-md-3">
                        <LandCover />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div >
        <div className="col-md-6">
          <Card
            className="m-1"
            body
            style={{
              backgroundColor: "#fff",
              borderColor: "#f5f5f5",
              height: "85vh",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-md-3" style={{ fontSize: "12px" }}>
                <Label>Select Province</Label>
                <Select
                  options={provinceObject}
                  value={{
                    value: provinceName,
                    label: provinceName,
                  }}
                  onChange={(obj) => {
                    dispatch(changeProvinceName(obj["value"]));
                    dispatch(changeDistrictName(null));
                    dispatch(changeBlockName(null));
                    dispatch(changeCampName(null));
                  }}
                  required
                  styles={customStyles}
                />
              </div>{" "}
              <div className="col-md-3" style={{ fontSize: "12px" }}>
                <Label>Select District</Label>
                <Select
                  options={districtObject}
                  value={{
                    value: districtName,
                    label: districtName,
                  }}
                  onChange={(obj) => {
                    dispatch(changeDistrictName(obj["value"]));
                    dispatch(changeBlockName(null));
                    dispatch(changeCampName(null));
                  }}
                  required
                  styles={customStyles}
                />
              </div>{" "}
              <div className="col-md-3" style={{ fontSize: "12px" }}>
                <Label>Select Block</Label>
                <Select
                  options={blockObject}
                  value={{
                    value: blockName,
                    label: blockName,
                  }}
                  onChange={(obj) => {
                    dispatch(changeBlockName(obj["value"]));
                    dispatch(changeCampName(null));
                  }}
                  required
                  styles={customStyles}
                />
              </div>{" "}
              <div className="col-md-3" style={{ fontSize: "12px" }}>
                <Label>Select Camp</Label>
                <Select
                  options={campObject}
                  value={{
                    value: campName,
                    label: campName,
                  }}
                  onChange={(obj) => dispatch(changeCampName(obj["value"]))}
                  required
                  styles={customStyles}
                />
              </div>{" "}
            </div>
            {campName !== null &&

              landCover.length !== 0 ? (
              <>
                <CardBody className="scroll">
                  <div className="row justify-content-between">
                    <div className="col-md-4">
                      <h5 style={{ fontWeight: "bold" }}>{campName}</h5>
                    </div>
                  </div>
                  <CardTitle
                    tag="h6"
                    style={{ color: "#0039E6" }}
                    className="text-center mt-5"
                  >
                    Land Cover
                  </CardTitle>

                  <div className="row justify-content-center">
                    <div className="col-md-10">
                      <Pie data={landCover} />
                    </div>
                  </div>
                  {landCoverBulkLoading ? (<LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    Downloading...
                  </LoadingButton>) :
                    (<>
                      <SimpleDialog
                        sendCheckList={handleCheckListLandCover}
                        handleDownload={handleBulkLandcoverDownload}
                      />
                    </>
                    )}

                  <CardTitle
                    tag="h6"
                    style={{ color: "#0039E6" }}
                    className="text-center mt-5"
                  >
                    NDVI Timeseries
                    <div
                        className="shadow"
                        style={{
                          borderRadius: "50%",
                          borderColor: "#0039E6",
                          backgroundColor: "#0039E6",
                          height: "22px",
                          width: "22px",
                          justifyContent: "center",
                          display: "inline-flex",
                          cursor:'pointer',
                          margin:"0px 0px 0px 4px"
                        }}
                        onClick={ndviWardDownload}
                      >
                        <AiOutlineDownload
                          size={16}
                          style={{ color: "#fff", margin: "4px" }}
                        />
                      </div>
                  </CardTitle>
                  {isNDVINullMoreThan25Percent(ndvi) ? (
                    <CardTitle style={{ textAlign: 'left', border: '1px solid #D3D3D3', padding: '10px', marginBottom: '20px', borderRadius: '5px' }}>
                      <p> NDVI data is not available, possible reason can be due to :</p>
                      <p> 1. Cropland in this camp is less than 15% of total area.</p>
                      <p> 2. NDVI in the some pixels cannot be captured due to resolution mismatch.</p>
                      <p> 3. The data available is less than 75% of historical data.</p>
                    </CardTitle>
                  ) : (
                    <TimeSeries
                      data={ndvi}
                      campName={campName}
                      type={"ndvi"}
                    />)
                  }
                  {ndviBulkLoading ? (<LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    Downloading...
                  </LoadingButton>) : (
                    <>
                      <SimpleDialog
                        sendCheckList={handleCheckListNDVI}
                        handleDownload={handleBulkNDVIDownload}
                      />
                    </>
                  )}

                  <CardTitle
                    tag="h6"
                    style={{ color: "#0039E6" }}
                    className="text-center mt-5"
                  >
                    CHIRPS Yearly Precipitation{" "}
                    <div
                        className="shadow"
                        style={{
                          borderRadius: "50%",
                          borderColor: "#0039E6",
                          backgroundColor: "#0039E6",
                          height: "22px",
                          width: "22px",
                          justifyContent: "center",
                          display: "inline-flex",
                          cursor:'pointer',
                          margin:"0px 0px 0px 4px"
                        }}
                        onClick={chirpsYearlyWardDownload}
                      >
                        <AiOutlineDownload
                          size={16}
                          style={{ color: "#fff", margin: "4px" }}
                        />
                      </div>
                  </CardTitle>
                  <ColumnChart
                    data={yearlyRainfall}
                    type={"Data"}
                    state={provinceName}
                    name={campName}
                  />
                  {chirpsYearlyBulkLoading ? (<LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    Downloading...
                  </LoadingButton>) : (
                    <>
                      <SimpleDialog
                        sendCheckList={handleCheckListChirpsYearly}
                        handleDownload={handleBulkYearlyChirpsDownload}
                      />
                    </>
                  )}

                  {loading ? (
                    <>
                      <CardTitle
                        tag="h6"
                        style={{ color: "#0039E6" }}
                        className="text-center mt-5"
                      >
                        CHIRPS Monthly Precipitation{" "}
                        <div
                        className="shadow"
                        style={{
                          borderRadius: "50%",
                          borderColor: "#0039E6",
                          backgroundColor: "#0039E6",
                          height: "22px",
                          width: "22px",
                          justifyContent: "center",
                          display: "inline-flex",
                          cursor:'pointer',
                          margin:"0px 0px 0px 4px"
                        }}
                        onClick={chirpsMonthlyWardDownload}
                      >
                        <AiOutlineDownload
                          size={16}
                          style={{ color: "#fff", margin: "4px" }}
                        />
                      </div>
                      </CardTitle>
                      <ColumnChart
                        data={monthlyRainfall}
                        type={"Data"}
                        campName={campName}
                      />
                      {chirpsMonthlyBulkLoading ? (<LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                      >
                        Downloading...
                      </LoadingButton>) : (
                        <>
                          <SimpleDialog
                            sendCheckList={handleCheckListChirpsMonthly}
                            handleDownload={handleBulkMonthlyChirpsDownload}
                          />
                        </>
                      )}

                      <CardTitle
                        tag="h6"
                        style={{ color: "#0039E6" }}
                        className="text-center mt-5"
                      >
                        Tamsat Yearly Rainfall{" "}
                        <div
                        className="shadow"
                        style={{
                          borderRadius: "50%",
                          borderColor: "#0039E6",
                          backgroundColor: "#0039E6",
                          height: "22px",
                          width: "22px",
                          justifyContent: "center",
                          display: "inline-flex",
                          cursor:'pointer',
                          margin:"0px 0px 0px 4px"
                        }}
                        onClick={tamsatYearlyWardDownload}
                      >
                        <AiOutlineDownload
                          size={16}
                          style={{ color: "#fff", margin: "4px" }}
                        />
                      </div>
                      </CardTitle>
                      <ColumnChart
                        data={tamsatyearlyRainfall}
                        type={"Data"}
                        state={provinceName}
                        name={campName}
                      />
                      {tamsatYearlyBulkLoading ? (<LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                      >
                        Downloading...
                      </LoadingButton>) : (
                        <>
                          <SimpleDialog
                            sendCheckList={handleCheckListTamsatYearly}
                            handleDownload={handleBulkYearlyTamsatDownload}
                          />

                        </>
                      )}

                      <CardTitle
                        tag="h6"
                        style={{ color: "#0039E6" }}
                        className="text-center mt-5"
                      >
                        Tamsat Monthly Rainfall{" "}
                        <div
                        className="shadow"
                        style={{
                          borderRadius: "50%",
                          borderColor: "#0039E6",
                          backgroundColor: "#0039E6",
                          height: "22px",
                          width: "22px",
                          justifyContent: "center",
                          display: "inline-flex",
                          cursor:'pointer',
                          margin:"0px 0px 0px 4px"
                        }}
                        onClick={tamsatMonthlyWardDownload}
                      >
                        <AiOutlineDownload
                          size={16}
                          style={{ color: "#fff", margin: "4px" }}
                        />
                      </div>
                      </CardTitle>
                      <ColumnChart
                        data={tamsatmonthlyRainfall}
                        type={"Data"}
                        state={provinceName}
                        name={campName}
                      />
                      {tamsatMonthlyBulkLoading ? (<LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                      >
                        Downloading...
                      </LoadingButton>) : (
                        <>
                          <SimpleDialog
                            sendCheckList={handleCheckListTamsatMonthly}
                            handleDownload={handleBulkMonthlyTamsatDownload}
                          />

                        </>
                      )}

                      <CardTitle
                        tag="h6"
                        style={{ color: "#0039E6" }}
                        className="text-center mt-5 mb-3"
                      >
                        Tamsat Daily Rainfall{" "}
                        <div
                        className="shadow"
                        style={{
                          borderRadius: "50%",
                          borderColor: "#0039E6",
                          backgroundColor: "#0039E6",
                          height: "22px",
                          width: "22px",
                          justifyContent: "center",
                          display: "inline-flex",
                          cursor:'pointer',
                          margin:"0px 0px 0px 4px"
                        }}
                        onClick={tamsatDailyWardDownload}
                      >
                        <AiOutlineDownload
                          size={16}
                          style={{ color: "#fff", margin: "4px" }}
                        />
                      </div>
                      </CardTitle>
                      <TimeSeries
                        data={tamsatdailyRainfall}
                        campName={campName}
                        type={"rainfall"}
                      />

                      {tamsatDailyBulkLoading ? (<LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                      >
                        Downloading...
                      </LoadingButton>) : (<>
                        <SimpleDialog
                          sendCheckList={handleCheckListTamsatDaily}
                          handleDownload={handleBulkDailyTamsatDownload}
                        />

                      </>
                      )}
                    </>
                  ) : (
                    <div className="text-center mt-5">
                      <Spinner color="dark" />
                    </div>
                  )}
                </CardBody>
              </>
            ) : (
              <>
                <h6 className="mt-2">Please select camp</h6>
              </>
            )}
          </Card>
        </div>
      </div >
    </>
  );
};
