export function changeProvinceName(data) {
  return {
    type: "CHANGE_PROVINCE_NAME",
    payload: { provinceName: data },
  };
}

export function changeDistrictName(data) {
  return {
    type: "CHANGE_DISTRICT_NAME",
    payload: { districtName: data },
  };
}
export function changeCampName(data) {
  return {
    type: "CHANGE_CAMP_NAME",
    payload: { campName: data },
  };
}

export function changeBlockName(data) {
  return {
    type: "CHANGE_BLOCK_NAME",
    payload: { blockName: data },
  };
}

